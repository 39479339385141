import gql from 'graphql-tag';

export default {
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data () {
    return {
      selectedFilters: {},
      filtersLoading: true,
    };
  },
  apollo: {
    filtersOptions: {
      query () {
        return this.createFiltersOptionsQuery(this.filters);
      },
      skip () {
        return !this.createFiltersOptionsQuery(this.filters);
      },
      update (data) {
        return data;
      }
    },
  },
  created () {
    for (const filter of this.filters) {
      if (filter.defaultValue !== null || filter.defaultValue !== undefined) {
        this.updateFilters({ key: filter.id, value: filter.defaultValue });
      }
    }
    this.filtersLoading = false;
  },
  methods: {
    updateFilters ({ key, value }) {
      this.$set(this.selectedFilters, key, value);
    },
    getFilterValue () {
      const selectedFiltersArray = Object.keys(this.selectedFilters)
        .map(selectedFilterId => {
          const filterValue = this.selectedFilters[selectedFilterId];
          const filterConfig = this.filters.find(filter => filter.id === selectedFilterId);

          if (selectedFilterId === 'dates' && this.selectedFilters[selectedFilterId].length > 0) {
            let result = `${filterConfig.whereClause[0]}:${this.selectedFilters[selectedFilterId][0]}`;

            if (this.selectedFilters[selectedFilterId][1]) {
              result = `${result},${filterConfig.whereClause[1]}:${this.selectedFilters[selectedFilterId][1]} 23-59-59`;
            }

            return result;
          } else if (typeof filterValue === 'object') {
            const separator = filterConfig.clauseSeparator || '(&&)';
            const concatValues = filterValue.map(item => item.value).join(separator);

            return `${filterConfig.whereClause}:${concatValues}`;
          }

          if (filterValue === undefined || filterConfig == null) {
            return null;
          }

          return `${filterConfig.whereClause}:${filterValue}`;
        })
        .filter(filterItem => filterItem !== null);

      return selectedFiltersArray.join(',');
    },
    createFiltersOptionsQuery (filters = []) {
      const requestFilters = filters.filter(filter => filter.type === 'select' && !filter.options);

      if (!requestFilters.length) {
        return false;
      }

      const queries = requestFilters.map(filter => `${filter.id}: filter (key: "${filter.id}") { value: key, text: value }`);

      return gql`{ ${queries.join(',')} }`;
    },
  },
  computed: {
    filtersWithOptions () {
      const translateOption = option => ({ value: option.value, text: this.$t(option.text) });
      if (!Array.isArray(this.filters)) return [];
      return this.filters.map(filterConfig => {
        const filter = { ...filterConfig };

        if (this.filtersOptions && this.filtersOptions[filter.id]) {
          filter.options = this.filtersOptions[filter.id].map(translateOption);
        } else if (filter.options) {
          filter.options = filter.options.map(translateOption);
        }

        return filter;
      });
    },
  },
};
