<template>
  <v-card>
    <v-card-title v-t="title" />
    <v-card-text>
      <filters-form
        v-if="filtersWithOptions.length > 0"
        :items="filtersWithOptions"
        :value="selectedFilters"
        :search="false"
        :type-name="'typeName'"
        class="mb-4"
        @update:value="$emit('updateFilters', $event)"
      />
      <slot v-if="!loading" />
      <!-- Loading spinner -->
      <BaseSpinner v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import filterMixins from '../mixins/filter';

export default {
  name: 'WidgetTemplate',
  mixins: [
    filterMixins,
  ],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      show: false,
    };
  },
};
</script>
