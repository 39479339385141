<template>
  <WidgetTemplate
    :title="title"
    :loading="isLoading"
    :filters="filters"
    @updateFilters="updateFilters"
  >
    <apexchart
      v-if="!isLoading && datas && !isEmpty"
      :options="datas.options"
      :series="datas.series"
      :height="options.style ? '100%' : 'auto'"
    />
    <p v-else>
      <span v-if="error">{{ error }}</span>
      <span v-else-if="isLoading">Loading...</span>
      <span v-else>No data to display</span>
    </p>
  </WidgetTemplate>
</template>

<script>
import gql from 'graphql-tag';
import VueApexCharts from 'vue-apexcharts';
import {get} from 'lodash';
import filterMixins from '../mixins/filter';
import WidgetTemplate from './WidgetTemplate';

export default {
  name: 'ChartsArea',
  components: {
    'apexchart': VueApexCharts,
    WidgetTemplate,
  },
  mixins: [
    filterMixins,
  ],
  props: {
    title: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              position: "bottom"
            }
          }
        },
      ],
      error: null,
      query: `
        query Query($query: String, $chartType: String, $groupByVariable: String, $labels: [String]){
          datas: chart(query: $query, chartType: $chartType, groupByVariable: $groupByVariable, labels: $labels) {
            options {
              chart { type },
              labels,
              xaxis { categories }
            }
            series
          }
        }
      `,
    };
  },
  apollo: {
    datas: {
      query () {
        return gql(this.query);
      },
      variables () {
        return this.gqlVariables;
      },
      skip () {
        return this.filtersLoading;
      },
      result (data) {
        if (data.error) {
          this.error = data.error;
          return;
        }
        this.$set(data.data.datas.options, 'legend', {position: 'bottom'});
        return data.data.datas;
      }
    },
  },
  computed: {
    gqlVariables () {
      let filter = this.options.filter ? this.options.filter : null;
      if (Object.keys(this.selectedFilters).length > 0) {
        filter = filter ? `${filter},${this.getFilterValue()}` : this.getFilterValue();
      }
      let query = `
        query {
          datas: ${this.options.id} ${filter ? `(queryInput: { filter: "${filter}" })` : ''} {
            id,
            ${this.options.related}
          }
        }
      `;
      if (this.options.filterValue) {
        query = query.replace(`\${${this.options.filterValue.key}}`, get(this, this.options.filterValue.value));
      }
      return {
        query,
        chartType: this.options.type,
        groupByVariable: this.options.groupBy,
        labels: this.options.labels
      };
    },
    isLoading () {
      return this.$apolloData.queries.datas.loading;
    },
    isEmpty () {
      switch (this.options.type) {
        case 'area':
        case 'pie':
          return !get(this.datas, 'options.xaxis.categories', []).length && !get(this.datas, 'series[0].data', []).length;
      }
      return false;
    }
  },
  beforeDestroy () {
    this.$apollo.queries.datas.stop();
  },
};
</script>
